<template>
  <div>
    <el-form ref="conditionForm" :rules="conditionRules" :model="conditionForm" label-width="80px">
      <el-form-item label="导出时间" prop="time" class="custom-form-item">
        <el-date-picker v-model="conditionForm.time" type="date" placeholder="选择日期" style="width: 100%;">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="仓房类型" prop="type" v-if="!hideType" class="custom-form-item">
        <el-select v-model="conditionForm.type" placeholder="请选择" style="width: 100%;">
          <el-option v-for="(item,index) in cangTypes" :key="index" :value="item.value" :label="item.name">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="bottom-btn">
      <el-button type="primary" @click="onFormSubmit('conditionForm')">确 定</el-button>
      <el-button @click="closeDialog">关 闭</el-button>
    </div>
  </div>
</template>

<script>
  import common from "@/common/index.js"
  var moment = require('moment');
  export default {
    props: {
      hideType: {
        type: Boolean,
        default: false
      },
      state: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        cangTypes: common.cangTypes,
        conditionForm: {
          type: '',
          time: ''
        },
        conditionRules: {
          type: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          time: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }]
        }
      }
    },
    methods: {
      //导出打印提交
      onFormSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
             this.$emit("close", {})
            if (!this.state) {
              if (this.hideType) {
                this.$axios.Get(this.$api.testingTempExcleSumman, {
                  time: moment(this.conditionForm.time).format('YYYY-MM-DD')
                }).then(res => {
                  window.open(this.$api.BaseUrl + "file/down-temp-xls/" + res.data.downName + "?fileName=" + res
                    .data
                    .fileName +
                    "&code=" + res.data.code);
                }).catch(res => {
                  if (res.errMsg) {
                    this.$message({
                      message: res.errMsg,
                      type: 'error'
                    });
                  }
                })
              } else {
                this.$axios.Get(this.$api.testingTempExcle + this.conditionForm.type, {
                  time: moment(this.conditionForm.time).format('YYYY-MM-DD')
                }).then(res => {
                  window.open(this.$api.BaseUrl + "file/down-temp-xls/" + res.data.downName + "?fileName=" + res
                    .data
                    .fileName +
                    "&code=" + res.data.code);
                }).catch(res => {
                  if (res.errMsg) {
                    this.$message({
                      message: res.errMsg,
                      type: 'error'
                    });
                  }
                })
              }
            } else {
              if (this.hideType) {
                let routeData = this.$router.resolve({
                  path: "/print/Sum",
                  query: {
                    time: moment(this.conditionForm.time).format('YYYY-MM-DD')
                  }
                });
                window.open(routeData.href, '_blank');
              } else {
                let routeData = this.$router.resolve({
                  path: "/print/Detail",
                  query: {
                    time: moment(this.conditionForm.time).format('YYYY-MM-DD'),
                    type: this.conditionForm.type
                  }
                });
                window.open(routeData.href, '_blank');
              }
            }
          } else {
            return false;
          }
        });
      },
      //关闭
      closeDialog() {
        this.$refs['conditionForm'].resetFields();
        this.$emit("close", {})
      }
    }
  }
</script>

<style>
</style>
